// Initialize first
@import '../init';

// Style Here
.sign-in {
  .landing {
    position: relative;
    overflow: hidden;

    .container {
      max-width: 460px;
      display: flex;
      margin-top: toRem(150);
      margin-bottom: toRem(74);
      padding: toRem(48) toRem(40);
      flex-direction: column;
      gap: toRem(40);
      align-items: flex-start;
      border-radius: 10px;
      background-color: $color-white;

      @include media-breakpoint-down(sm) {
        width: 100%;
        border-radius: unset;
        margin-top: toRem(72);
        padding: toRem(64) toRem(20);
      }

      .text-primary {
        color: $color-dark-purple !important;
      }
    }

    .form__header {
      display: flex;
      flex-direction: column;
      gap: toRem(12);
      width: 100%;

      h3 {
        color: $color-black;
      }
    }

    .form__body {
      display: flex;
      flex-direction: column;
      gap: toRem(24);
      width: 100%;

      //   Form Body
      .form-wrap {
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        .form-group__password {
          margin-top: toRem(16);
          margin-bottom: toRem(6);
        }

        .form-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        .form-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: toRem(16);

          .link {
            display: unset;
          }
        }
      }

      //   Form Separator
      .form-separator {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        p {
          margin-inline: toRem(13);
        }

        .line-separator {
          height: 1px;
          background-color: $color-lighter-grey;
          width: 100%;
        }
      }

      //   Form Footer
      .form-footer {
        display: flex;
        flex-direction: column;
        gap: toRem(16);
      }
    }
  }
}
